@import url(./variables.css);
@import url(./components/scrollbar.scss);
@import url(./components/animations.scss);
@import url(./components/swiper.scss);
@import url(./components/loaders.scss);

.Main_Body{
  background-image: linear-gradient(to right, #000046 , #1CB5E0);
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}

.full_height {
  height: 100vh;
}

.custom_sidebar {
  height: 100vh;

  &-open {
    width: 280px;
    display: flex;
  }

  &-closed {
    display: none;
    width: 0;
  }
}

.custom_body {
  max-width: 1520px;
  margin: 0 auto;

  &-closed {
    width: calc(100% - 280px);
  }

  &-full {
    width: 100%;
  }
}

.card_shadow {
  box-shadow: 0 0.5rem 1rem #99999930;
}

.table_head th {
  color: #5e8cce;
  font-weight: 600;
}

.table_item td {
  color: #5e8cce;
}

.payment_tab button.nav-link {
  border: none !important;
}

.payment_tab button.nav-link.active {
  border-bottom: 2px solid var(--color-primary) !important;
}

.cursor_pointer {
  cursor: pointer !important;
}

.payment_card {
  max-height: 420px;
}

.address_card {
  max-height: 350px;
}

.hero_bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* max-height: 93vh; */
}

.personal_bg {
  background-image: url("../assets/images/hero_2.png");
}

.business_bg {
  background-image: url("../assets/images/bbackground.png");
}

.hero_bg::after {
  content: "";
  background-color: rgba(255, 255, 255, 0.267);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.hero_button {
  background-color: #fff;
  transition: all .3s ease;
}

.hero_button:hover {
  background-color: var(--color-info);
  color: #fff;
}

.hero_button.selected {
  color: #fff;
  background-color: var(--color-info);
}

a.nav-link.active .hero_button {
  color: #fff;
  animation: blinkButton 1s linear;
  background-color: var(--color-info);
}

img.core_adv {
  width: 9rem;
  height: 6rem;
}

.pricing_list {
  list-style: none;
}

.pricing_image {
  height: 3rem;
  width: 3rem;
  object-fit: contain;
}

.direct_payment:hover .payment_button {
  fill: rgb(23, 190, 209);
  cursor: pointer;
}

.center_item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.stop_scroll {margin: 0; height: 100%; overflow: hidden}

.reg_image {
  display: none;

  @media (min-width: 764px) {
    display: flex;
  }
}

.waitlist_bg {
  background: url('../assets/images/waitlist_bg.jpg') no-repeat;
  background-size: cover;
}

.faucet_btn:hover {
  background-color: white;
  color:var(--color-primary);
  cursor: pointer;
}